import React, { useEffect, useRef } from "react";
import "./ServicesSection.css";
import Header from "../../Header/Header.jsx";
import { Colors } from "../../../assets/colors/colors.js";
import {
  PlasteringIcon,
  InsulationIcon,
  FacadeIcon,
  PaintIcon,
} from "../../../assets/Icons/Icons.jsx";
import Button from "../../Button/Button.jsx";
import { useTranslation } from "react-i18next";
import wall1 from "../../../assets/Icons/wall1.svg";
import wall2 from "../../../assets/Icons/wall2.svg";
import wall3 from "../../../assets/Icons/wall3.svg";
import wall4 from "../../../assets/Icons/wall4.svg";

const ServicesSection = () => {
  const serviceRowsRef = useRef([]);
  const { t } = useTranslation();

  useEffect(() => {
    const currentRows = serviceRowsRef.current;

    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target.classList.add("visible");
          }
        });
      },
      { threshold: 0 } // Trigger when any part of the element is visible
    );

    currentRows.forEach((row) => {
      if (row) observer.observe(row);
    });

    return () => {
      currentRows.forEach((row) => {
        if (row) observer.unobserve(row);
      });
    };
  }, []);

  const [currentImageIndex, setCurrentImageIndex] = React.useState(0);
  const [fadeState, setFadeState] = React.useState("fade-in"); // Manage the fade state
  const wallImages = [wall1, wall2, wall3, wall4];

  useEffect(() => {
    const imageInterval = setInterval(() => {
      setFadeState("fade-out"); // Start fade out

      setTimeout(() => {
        setCurrentImageIndex(
          (prevIndex) => (prevIndex + 1) % wallImages.length
        );
        setFadeState("fade-in"); // Start fade in
      }, 1000); // Change the image after the fade-out duration
    }, 4000); // Change image every 5 seconds

    return () => clearInterval(imageInterval);
  }, [wallImages.length]);

  return (
    <section className="paddingV services-lightG">
      <div className="services-header">
        <Header
          top={t("ServicesSection.header")}
          arrowColor={Colors.white}
          bgColor={Colors.primaryRed}
          textColor={Colors.black}
        />
      </div>
      <div className="service-columns paddingH jeju-font">
        <div className="wall-image-container">
          <img
            src={wallImages[currentImageIndex]}
            alt={`Wall Detail ${currentImageIndex + 1}`}
            className={`wall-image ${fadeState}`}
          />
        </div>
        <div className="services-grid">
          <div
            className="services-row"
            ref={(el) => (serviceRowsRef.current[0] = el)}
          >
            <PlasteringIcon />
            <p className="services-m0">
              <span className="jockey-font">
                {t("ServicesSection.plastering")}
              </span>{" "}
              {t("ServicesSection.plasteringDsc")}
            </p>
          </div>
          <div
            className="services-row"
            ref={(el) => (serviceRowsRef.current[1] = el)}
          >
            <InsulationIcon />
            <p className="services-m0">
              <span className="jockey-font">
                {t("ServicesSection.wallInsulation")}
              </span>{" "}
              {t("ServicesSection.wallInsulationDsc")}
            </p>
          </div>
        </div>
        <div className="services-grid">
          <div
            className="services-row"
            ref={(el) => (serviceRowsRef.current[2] = el)}
          >
            <FacadeIcon />
            <p className="services-m0">
              <span className="jockey-font">{t("ServicesSection.facade")}</span>{" "}
              {t("ServicesSection.facadeDsc")}
            </p>
          </div>
          <div
            className="services-row"
            ref={(el) => (serviceRowsRef.current[3] = el)}
          >
            <PaintIcon />
            <p className="services-m0">
              <span className="jockey-font">
                {t("ServicesSection.painting")}
              </span>{" "}
              {t("ServicesSection.paintingDsc")}
            </p>
          </div>
        </div>
      </div>
      <div className="services-button-div paddingH">
        <a href="/services" alt="This link redirect you to services page!">
          <Button
            text={t("ServicesSection.checkAllServices")}
            bgColor={Colors.primaryRed}
            textColor={Colors.white}
            onClick={() => {}}
          />
        </a>
      </div>
    </section>
  );
};

export default ServicesSection;
