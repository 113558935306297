import React from "react";
import "./Footer.css";
import {
  FacebookIcon,
  InstagramIcon,
  MailIcon,
  PhoneIcon,
  WhatsappIcon,
} from "../../assets/Icons/Icons";
import { Colors } from "../../assets/colors/colors";
import logo from './LogoFooter.svg';

const Footer = () => {
  return (
    <footer className="footer-container paddingH">
      <div className="footer-copyright">©Copyright. All rights reserved</div>
      <div className="footer-logo">
        <img src={logo} alt="Befix logo" />
      </div>
      <div className="footer-icons">
        <a className="footer-icon-link" href="mailto:befixag@gmail.com" alt="This link redirects you to Mail App!">
          <MailIcon color1={Colors.white} color2={Colors.gray} />
        </a>
        <a className="footer-icon-link" href="tel:+38971601608" alt="This link allows you to call the owner!">
          <PhoneIcon color1={Colors.white} color2={Colors.gray} />
        </a>
        <a
          className="footer-icon-link"
          href="https://www.facebook.com/befixag"
          target="_blank"
          rel="noopener noreferrer"
          alt="This link redirects you to Befix Facebook page!"
        >
          <FacebookIcon color1={Colors.white} />
        </a>
        <a
          className="footer-icon-link"
          href="https://wa.me/+38971601608"
          target="_blank"
          rel="noopener noreferrer"
          alt="This link redirects you to Whatsapp App to contact the owner!"
        >
          <WhatsappIcon color1={Colors.white} />
        </a>
        <a
          className="footer-icon-link"
          href="https://www.instagram.com/befix.ag"
          target="_blank"
          rel="noopener noreferrer"
          alt="This link redirects you to Befix Instagram page!"
        >
          <InstagramIcon color1={Colors.white} />
        </a>
      </div>
    </footer>
  );
};

export default Footer;
