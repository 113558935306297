import React, { useState } from "react";
import { MapContainer, TileLayer, Marker, Popup } from "react-leaflet";
import "./ContactSection.css";
import "leaflet/dist/leaflet.css";
import emailjs from "emailjs-com";
import L from "leaflet";
import LocationIcon from "../../../assets/Icons/location.svg";
import { useTranslation } from "react-i18next";
import { MoonLoader } from "react-spinners";

const ContactSection = () => {
  const { t } = useTranslation();

  const customIcon = new L.Icon({
    iconUrl: LocationIcon,
    iconSize: [38, 38], // Size of the icon
    iconAnchor: [19, 38], // Anchor point of the icon
    popupAnchor: [0, -38], // Popup anchor point relative to the icon
  });

  const [formData, setFormData] = useState({
    fullName: "",
    email: "",
    phone: "",
    message: "",
  });

  const [loading, setLoading] = useState(false); // Add loading state
  const [showSuccessModal, setShowSuccessModal] = useState(false); // Add modal state

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const sendEmail = (e) => {
    e.preventDefault();
    setLoading(true); // Set loading to true when the form is submitted

    emailjs
      .sendForm(
        "service_6h824fa",
        "template_tnou695",
        e.target,
        "byDKIa2UX8pu-AM38"
      )
      .then(
        () => {
          setLoading(false); // Stop loading
          setShowSuccessModal(true); // Show the success modal
          setFormData({
            fullName: "",
            email: "",
            phone: "",
            message: "",
          });
        },
        (error) => {
          console.error("Failed to send email.", error.text);
          setLoading(false); // Stop loading if the email fails
        }
      );
  };

  return (
    <div className="contact-section paddingV">
      <div className="map-container">
        <MapContainer
          center={[42.0363437, 21.0025]}
          zoom={15}
          className="contact-height-width"
        >
          <TileLayer
            url="https://{s}.basemaps.cartocdn.com/dark_all/{z}/{x}/{y}{r}.png"
            attribution='&copy; <a href="https://www.openstreetmap.org/copyright" alt="This link redirect you to Befix location!">>OpenStreetMap</a> contributors'
          />
          <Marker position={[42.0365937, 21.0025]} icon={customIcon}>
            <Popup>
              <a
                href="https://maps.app.goo.gl/FhDBZrjkLtLRZhyA6"
                target="_blank"
                rel="noopener noreferrer"
              >
                Džepčište, North Macedonia.
              </a>
            </Popup>
          </Marker>
        </MapContainer>
      </div>

      <form className="contact-form" onSubmit={sendEmail}>
        <div className="contact-p40">
          <h2 className="jockey-font contact-fs35">
            {t("ContactSection.contactUs")}
          </h2>
          <p className="jeju-font primaryRedText contact-fs18">
            {t("ContactSection.reachOut")}
          </p>
        </div>
        <div className="contact-p40-2">
          <input
            type="text"
            name="fullName"
            placeholder={t("ContactSection.fullName")}
            className="jeju-font contact-input"
            value={formData.fullName}
            onChange={handleInputChange}
            required
          />
          <input
            type="email"
            name="email"
            placeholder={t("ContactSection.yourEmail")}
            className="jeju-font contact-input"
            value={formData.email}
            onChange={handleInputChange}
            required
          />
          <input
            type="tel"
            name="phone"
            placeholder={t("ContactSection.phone")}
            className="jeju-font contact-input"
            value={formData.phone}
            onChange={handleInputChange}
          />
          <textarea
            name="message"
            placeholder={t("ContactSection.message")}
            className="jeju-font contact-textarea"
            value={formData.message}
            onChange={handleInputChange}
            required
          ></textarea>
        </div>
        <div className="contact-button-layout">
          <button
            type="submit"
            className="contact-button jeju-font contact-btn-width"
            disabled={loading}
          >
            {loading ? (
              <div className="contact-btn-inner">
                <MoonLoader size={15} color="white" />
                <p className="jeju-font contact-fs18">
                  {t("ContactSection.sending")}
                </p>
              </div>
            ) : (
              <p className="jeju-font contact-fs18">
                {t("ContactSection.submit")}
              </p>
            )}{" "}
          </button>
        </div>
      </form>

      {/* Success Modal */}
      {showSuccessModal && (
        <div className="modal">
          <div className="modal-content">
            <h2>{t("ContactSection.success")}</h2>
            <button onClick={() => setShowSuccessModal(false)}>
              {t("ContactSection.close")}
            </button>
          </div>
        </div>
      )}

      <div className="empty-div"></div>
    </div>
  );
};

export default ContactSection;
