import React from "react";
import "./GallerySection.css";
import Header from "../../Header/Header.jsx";
import { Colors } from "../../../assets/colors/colors.js";
import {
  GalleryBgIcon,
  NextButton,
  NextButtonSmall,
} from "../../../assets/Icons/Icons.jsx";
import { useTranslation } from "react-i18next";

const GallerySection = () => {
  const isSmallScreen = window.innerWidth <= 768;
  const { t } = useTranslation();
  return (
    <section className="gallery-container">
      <div className="gallery-header">
        <Header
          top={t("GallerySection.header")}
          arrowColor={Colors.white}
          bgColor={Colors.primaryRed}
          textColor1={Colors.black}
        />
      </div>
      <div className="gallery-content paddingH">
        <div className="gallery-description">
          {t("GallerySection.discover")}{" "}
        </div>
        <div className="gallery-images">
          <div className="gallery-image-row">
            <a href="/gallery" className="image-red" alt="This link redirect you to gallery page!">
              <div />
            </a>
            <a href="/gallery" className="image-blue" alt="This link redirect you to gallery page!">
              <div />
            </a>
            <a href="/gallery" className="image-pink" alt="This link redirect you to gallery page!">
              <div />
            </a>
            {isSmallScreen ? (
              <button className="next-button">
              <a href="/gallery" alt="This link redirect you to gallery page!">
                <NextButtonSmall />
              </a>
            </button>
            ) : (
              <button className="next-button">
              <a href="/gallery" alt="This link redirect you to gallery page!">
                <NextButton />
              </a>
            </button>
            )}
            
          </div>
        </div>
      </div>
      <div className="gallery-bg-icon">
        <GalleryBgIcon />
      </div>
    </section>
  );
};

export default GallerySection;
