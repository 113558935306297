import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Landing from "./screens/Landing/Landing";
import About from "./screens/About/About";
import Gallery from "./screens/Gallery/Gallery";
import Services from "./screens/Services/Services";
import { HelmetProvider } from "react-helmet-async";
import Error from "./screens/Error/Error";

function App() {
  return (
    <HelmetProvider>
      <Router>
        <Routes>
          <Route path="/" element={<Landing />} />
          <Route path="/about" element={<About />} />
          <Route path="/gallery" element={<Gallery />} />
          <Route path="/services" element={<Services />} />
          <Route path="/*" element={<Error />} />
        </Routes>
      </Router>
    </HelmetProvider>
  );
}

export default App;
