import React from "react";
import "./Button.css";

const Button = ({ bgColor, text, textColor, onClick }) => {
  return (
    <button
      onClick={onClick}
      style={{ backgroundColor: bgColor }}
      className="button-container"
    >
      <p style={{ color: textColor }} className="jeju-font">
        {text}
      </p>
    </button>
  );
};

export default Button;
