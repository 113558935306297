import React, { useState, useEffect } from "react";
import "./Gallery.css"; // Import the custom styles
import media from "../../assets/Images/gallery/mediaData.js"; // Import the media array (85 items)
import Navbar from "../../components/Navbar/Navbar.jsx";
import Footer from "../../components/Footer/Footer.jsx";
import Header from "../../components/Header/Header.jsx";
import { Colors } from "../../assets/colors/colors.js";
import { ExpandIcon } from "../../assets/Icons/Icons.jsx";
import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet-async";

const Gallery = () => {
  const { t } = useTranslation();
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth <= 768);
  const [isModalOpen, setIsModalOpen] = useState(false); // State to control the modal visibility

  const visibleThumbnailsCount = 7; // Visible active thumbnail + 6 others (3 before, 3 after)

  const goToNextSlide = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % media.length);
  };

  const goToPrevSlide = () => {
    const prevIndex = (currentIndex - 1 + media.length) % media.length;
    setCurrentIndex(prevIndex);
  };

  // Monitor window resizing
  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth <= 768);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  // Helper to get the previous and next image indices
  const getPreviousIndex = () =>
    currentIndex === 0 ? media.length - 1 : currentIndex - 1;
  const getNextIndex = () =>
    currentIndex === media.length - 1 ? 0 : currentIndex + 1;

  const getVisibleThumbnails = () => {
    let visibleThumbnails = [];

    for (let i = 0; i < visibleThumbnailsCount; i++) {
      const index =
        (currentIndex -
          Math.floor(visibleThumbnailsCount / 2) +
          i +
          media.length) %
        media.length;
      const thumbnail =
        media[index].type === "video"
          ? media[index].thumbnail
          : media[index].src;
      visibleThumbnails.push(thumbnail);
    }

    return visibleThumbnails;
  };

  const isVideo = (file) => {
    return file.type === "video";
  };

  const handleExpandClick = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  return (
    <>
      <Helmet>
        <title>
          Befix Project Gallery – Plastering, Insulation, Renovation & More
        </title>
        <meta
          name="description"
          content="Explore our gallery showcasing Befix's completed projects in plastering, wall insulation, renovation, interior and exterior design, and more across North Macedonia."
        />
        <meta
          name="keywords"
          content="Befix, befix, be-fix, Project gallery, Befix projects, Completed projects, Plastering gallery, Insulation gallery, Renovation gallery, Wall painting examples, Facade decoration, Interior design gallery, Exterior design gallery, Industrial flooring examples, Commercial renovation, Residential renovation, Construction gallery, Decorative walls, Gypsum board projects, Galeria e projekteve, Projektet Befix, Projekte të përfunduara, Galeri suvatimi, Galeri izolimi, Galeri rinovimi, Shembuj të lyerjes së mureve, Dekorim fasade, Galeri dizajn i brendshëm, Galeri dizajn i jashtëm, Shembuj dyshemesh industriale, Rinovim komercial, Rinovim rezidencial, Galeria e ndërtimit, Mure dekorative, Projekte dërrasash gipsi, Галерија на проекти, Проекти Befix, Завршени проекти, Галерија гипсање, Галерија изолација, Галерија реновирање, Примери на бојадисување на ѕидови, Декорација на фасада, Галерија на внатрешен дизајн, Галерија на надворешен дизајн, Индустриски подови, Комерцијално реновирање, Станбено реновирање, Галерија на изградба, Декоративни ѕидови, Гипс картон проекти"
        />

        {/* Open Graph Meta Tags for social sharing */}
        <meta
          property="og:title"
          content="Befix Project Gallery – Plastering, Insulation, Renovation & More"
        />
        <meta
          property="og:description"
          content="Explore Befix's completed projects in plastering, wall insulation, renovation, interior and exterior design, and more across North Macedonia."
        />
        <meta property="og:type" content="website" />
        <meta
          property="og:image"
          content="https://befix.mk/images/og-image.png"
        />
        <meta property="og:url" content="https://befix.mk/gallery" />

        {/* Twitter Card Meta Tags */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta
          name="twitter:title"
          content="Befix Project Gallery – Plastering, Insulation, Renovation & More"
        />
        <meta
          name="twitter:description"
          content="Explore our gallery showcasing Befix's completed projects in plastering, wall insulation, renovation, interior and exterior design, and more across North Macedonia."
        />
        <meta
          name="twitter:image"
          content="https://befix.mk/images/twitter-image.png"
        />
      </Helmet>
      <Navbar />
      <div className="gallery-screen-container">
        <div>
          <div className="gallery-screen-content">
            <Header
              top={t("GallerySection.header")}
              arrowColor={isSmallScreen ? Colors.primaryRed : Colors.white}
              bgColor={isSmallScreen ? Colors.white : Colors.primaryRed}
              textColor1={Colors.white}
            />
          </div>

          <div className="gallery-screen-wrapper">
            {/* Main image/video display */}
            <div className="gallery-slider">
              <div className="gallery-main-images">
                <button
                  className="gallery-arrow left-arrow"
                  onClick={goToPrevSlide}
                >
                  &#10094;
                </button>

                {isVideo(media[getPreviousIndex()]) ? (
                  <video
                    src={media[getPreviousIndex()].src}
                    className="gallery-side-image"
                    autoPlay={false}
                    poster={media[getPreviousIndex()].thumbnail}
                  />
                ) : (
                  <img
                    src={media[getPreviousIndex()].src}
                    alt="Previous"
                    className="gallery-side-image"
                  />
                )}

                {isVideo(media[currentIndex]) ? (
                  <video
                    src={media[currentIndex].src}
                    className="gallery-center-image"
                    controls
                    muted
                    autoPlay={false}
                    poster={media[currentIndex].thumbnail}
                  />
                ) : (
                  <>
                    <img
                      src={media[currentIndex].src}
                      alt={`Slide ${currentIndex + 1}`}
                      className="gallery-center-image"
                    />
                    <button
                      className="expand-button"
                      onClick={handleExpandClick}
                    >
                      <ExpandIcon />
                    </button>
                  </>
                )}

                {isVideo(media[getNextIndex()]) ? (
                  <video
                    src={media[getNextIndex()].src}
                    className="gallery-side-image"
                    autoPlay={false}
                    poster={media[getNextIndex()].thumbnail}
                  />
                ) : (
                  <img
                    src={media[getNextIndex()].src}
                    alt="Next"
                    className="gallery-side-image"
                  />
                )}

                <button
                  className="gallery-arrow right-arrow"
                  onClick={goToNextSlide}
                >
                  &#10095;
                </button>
              </div>
            </div>

            {/* Thumbnails */}
            <div className="gallery-thumbnails">
              {getVisibleThumbnails().map((image, index) => {
                const realIndex =
                  (currentIndex -
                    Math.floor(visibleThumbnailsCount / 2) +
                    index +
                    media.length) %
                  media.length;
                const isActive = realIndex === currentIndex;

                return (
                  <img
                    key={realIndex}
                    src={image}
                    alt={`Thumbnail ${realIndex + 1}`}
                    className={`gallery-thumbnail ${isActive ? "active" : ""}`}
                    onClick={() => setCurrentIndex(realIndex)}
                  />
                );
              })}
            </div>
          </div>
        </div>
        <Footer />
      </div>

      {isModalOpen && (
        <div className="modal" onClick={handleCloseModal}>
          <span className="close-modal" onClick={handleCloseModal}>
            &times;
          </span>
          <img
            className="modal-content"
            src={media[currentIndex].src}
            alt="Enlarged"
          />
        </div>
      )}
    </>
  );
};

export default Gallery;
