import React, { useState, useEffect } from "react";
import Navbar from "../../components/Navbar/Navbar";
import Footer from "../../components/Footer/Footer";
import "./About.css";
import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet-async";

const About = () => {
  const { t } = useTranslation();
  const [yearsExperience, setYearsExperience] = useState(0);
  const [teamMembers, setTeamMembers] = useState(0);
  const [projectsCompleted, setProjectsCompleted] = useState(0);
  const [satisfiedCustomers, setSatisfiedCustomers] = useState(0);

  useEffect(() => {
    const animateValue = (setValue, targetValue, duration) => {
      let start = 0;
      const stepTime = Math.abs(Math.floor(duration / targetValue));
      const timer = setInterval(() => {
        start += 1;
        setValue(start);
        if (start === targetValue) clearInterval(timer);
      }, stepTime);
    };

    animateValue(setYearsExperience, 20, 2000);
    animateValue(setTeamMembers, 30, 2000);
    animateValue(setProjectsCompleted, 100, 2000);
    animateValue(setSatisfiedCustomers, 98, 2000);
  }, []);

  return (
    <>
      <Helmet>
        <title>
          About Befix – Professional Plastering, Insulation, and Renovation
          Services in North Macedonia
        </title>
        <meta
          name="description"
          content="Learn more about Befix, a leading provider of plastering, insulation, and renovation services in North Macedonia. With years of experience, Befix is dedicated to quality and customer satisfaction in every project."
        />
        <meta
          name="keywords"
          content="Befix, Plastering company North Macedonia, Professional plastering, Insulation services, Renovation experts, Building renovation, Interior design, Exterior design, Wall painting, Construction services, Reliable plastering, Residential and commercial plastering, Experienced renovators, Home renovation, North Macedonia plastering, Skopje, Tetovo, Bitola, Ohrid, Prilep, Kumanovo, Strumica, Veles, Gostivar, Štip, Quality plastering services, Befix values, Commitment to excellence, Customer satisfaction, Trusted plastering company, Suvatim, Dekorim, Renovim, Maqedoni e Veriut, Гипс услуги, Изолација, Реновирање услуги"
        />

        {/* Open Graph Meta Tags for Social Sharing */}
        <meta
          property="og:title"
          content="Befix – Professional Plastering, Insulation, and Renovation Services"
        />
        <meta
          property="og:description"
          content="Discover Befix's dedication to quality in plastering, insulation, and other services across North Macedonia. Our mission is customer satisfaction and excellence in every project."
        />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://befix.mk/about" />
        <meta
          property="og:image"
          content="https://befix.mk/images/og-image.png"
        />

        {/* Twitter Card Meta Tags */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta
          name="twitter:title"
          content="Befix – Plastering, Insulation, and Renovation Experts"
        />
        <meta
          name="twitter:description"
          content="Befix, a trusted provider of plastering, insulation, and other services in North Macedonia, committed to quality and customer satisfaction."
        />
        <meta
          name="twitter:image"
          content="https://befix.mk/images/twitter-image.png"
        />
      </Helmet>
      <Navbar />
      <div className="about-screen-1 paddingH paddingV">
        <div className="about-screen-metrics-hidden jockey-font">
          <div className="about-screen-metric-block about-screen-red-text">
            <h1>{yearsExperience}+</h1>
            <h3>
              {t("AboutScreen.years")}
              <br />
              {t("AboutScreen.exp")}
            </h3>
          </div>
          <div className="about-screen-metric-block about-screen-grey-text">
            <h1>{teamMembers}+</h1>
            <h3>
              {t("AboutScreen.team")}
              <br />
              {t("AboutScreen.members")}
            </h3>
          </div>
          <div className="about-screen-metric-block about-screen-red-text">
            <h1>{projectsCompleted}+</h1>
            <h3>
              {t("AboutScreen.projects")}
              <br />
              {t("AboutScreen.completed")}
            </h3>
          </div>
          <div className="about-screen-metric-block about-screen-grey-text">
            <h1>{satisfiedCustomers}%</h1>
            <h3>
              {t("AboutScreen.satisfied")}
              <br />
              {t("AboutScreen.customers")}
            </h3>
          </div>
        </div>

        <div
          className="about-screen-description jeju-font"
          data-description="This section contains detailed company information."
        >
          <div className="about-screen-info">
            <h1 className="jockey-font">{t("AboutScreen.aboutUs")}</h1>
            <p>
              <span className="jockey-font">Befix,</span>{" "}
              {t("AboutScreen.yourTrusted")}
            </p>
            <p>
              {t("AboutScreen.with20")}{" "}
              <span className="jockey-font">Befix</span>{" "}
              {t("AboutScreen.fromSmall")}
            </p>

            <p>
              {t("AboutScreen.at")} <span className="jockey-font">Befix,</span>{" "}
              {t("AboutScreen.wePride")}
            </p>
          </div>

          <div className="about-screen-metrics jockey-font">
            <div className="about-screen-metric-block about-screen-red-text">
              <h1>{yearsExperience}+</h1>
              <h3>
                {t("AboutScreen.years")}
                <br />
                {t("AboutScreen.exp")}
              </h3>
            </div>
            <div className="about-screen-metric-block about-screen-grey-text ">
              <h1 className="about-gray-color">{teamMembers}+</h1>
              <h3>
                {t("AboutScreen.team")}
                <br />
                {t("AboutScreen.members")}
              </h3>
            </div>
            <div className="about-screen-metric-block about-screen-grey-text">
              <h1 className="about-gray-color">{projectsCompleted}+</h1>
              <h3>
                {t("AboutScreen.projects")}
                <br />
                {t("AboutScreen.completed")}
              </h3>
            </div>
            <div className="about-screen-metric-block about-screen-red-text">
              <h1 className="about-red-color">{satisfiedCustomers}%</h1>
              <h3>
                {t("AboutScreen.satisfied")}
                <br />
                {t("AboutScreen.customers")}
              </h3>
            </div>
          </div>

          <div className="about-screen-why-choose-us">
            <h1 className="jockey-font">{t("AboutScreen.whyUs")}</h1>
            <p>
              {t("AboutScreen.why1")}
              <br />
              {t("AboutScreen.why2")} <br />
              {t("AboutScreen.why3")}
              <br />
              {t("AboutScreen.why4")}
            </p>
            <p>{t("AboutScreen.join")}</p>
          </div>
        </div>
      </div>

      <div className="about-screen-2 paddingV paddingH">
        <div className="about-screen-partners-info">
          <h1 className="jockey-font">
            {t("AboutScreen.weWork")}
            <br />
            {t("AboutScreen.best")}
          </h1>
          <p className="jeju-font">{t("AboutScreen.while")}</p>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default About;
