import React from "react";
import "./AboutSection.css";
import Header from "../../Header/Header.jsx";
import { Colors } from "../../../assets/colors/colors.js";
import Button from "../../Button/Button.jsx";
import { useTranslation } from "react-i18next";

const AboutSection = () => {
  const { t } = useTranslation();
  return (
    <section className="about-container paddingV">
      <div className="about-header">
        <Header
          top={t("AboutSection.header")}
          arrowColor={Colors.primaryRed}
          bgColor={Colors.white}
          textColor1={Colors.white}
        />
      </div>
      <div className="about-description paddingH jeju-font ">
        <div>
          <span className="jockey-font">Befix</span>
          {t("AboutSection.ourTrusted")}
        </div>
        <div>
          {t("AboutSection.founded")} <span className="jockey-font">Befix</span>
          {t("AboutSection.hasGrown")}
        </div>
        <div>
          {t("AboutSection.at")} <span className="jockey-font">Befix,</span>
          {t("AboutSection.wePride")}
        </div>
        <a href="/about" alt="This link redirect you to about page!">
          <Button
            text={t("AboutSection.moreInfo")}
            bgColor={Colors.white}
            textColor={Colors.primaryRed}
            onClick={() => {}}
          />
        </a>
      </div>
    </section>
  );
};

export default AboutSection;
