import React from "react";
import "./Header.css";
import { RightArrow } from "../../assets/Icons/Icons.jsx";

const Header = ({ top, bottom, arrowColor, bgColor, textColor1, textColor2 }) => {
  return (
    <div className="header-container">
      <div
        style={{ backgroundColor: bgColor }}
        className="primaryRedBg header-box"
      >
        <RightArrow color={arrowColor} />
      </div>
      <div className="jeju-font header-title">
        {top ? <div style={{ color: textColor1 }}>{top}</div> : <div></div>}
        {bottom ? <div style={{ color: textColor2 }}>{bottom}</div> : <div></div>}
      </div>
    </div>
  );
};

export default Header;
