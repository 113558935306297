import React, { useState, useEffect, useMemo } from "react";
import "./HeroSection.css";
import { useTranslation } from "react-i18next";
import logo from "../../Footer/LogoRed.svg";

const HeroSection = () => {
  const { t, i18n } = useTranslation();
  const [text, setText] = useState("");
  const [isDeleting, setIsDeleting] = useState(false);
  const [loopNum, setLoopNum] = useState(0);
  const [typingSpeed, setTypingSpeed] = useState(150);
  const titles = useMemo(() => [t("HeroSection.subtitle")], [t]);

  useEffect(() => {
    const handleTyping = () => {
      const i = loopNum % titles.length;
      const fullText = titles[i];

      setText(
        isDeleting
          ? fullText.substring(0, text.length - 1)
          : fullText.substring(0, text.length + 1)
      );

      if (!isDeleting && text === fullText) {
        setTimeout(() => setIsDeleting(true), 1000); // Pause before starting deletion
      } else if (isDeleting && text === "") {
        setIsDeleting(false);
        setLoopNum(loopNum + 1);
      }

      const speed = isDeleting ? 50 : 150; // Speed for typing and deleting
      setTypingSpeed(speed);
    };

    const timer = setTimeout(handleTyping, typingSpeed);

    return () => clearTimeout(timer); // Cleanup the timeout
  }, [text, isDeleting, typingSpeed, loopNum, titles]);

  const isMacedonian = i18n.language === "mk" || i18n.language === "tr";

  return (
    <section className="hero-full-height paddingH">
      <div className="hero-flex">
        <img src={logo} alt="Befix logo" className="logo-animation" />
      </div>
      <div
        className={`jeju-font hero-title ${
          isMacedonian ? "hero-title-mk" : ""
        }`}
      >
        {text}
      </div>
      <div className="hero-description">{t("HeroSection.dsc")}</div>
    </section>
  );
};

export default HeroSection;
