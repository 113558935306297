import item1 from "./1.jpg";
import item2 from "./2.mp4";
import thumb2 from "./thumb2.webp";
import item3 from "./3.jpg";
import item4 from "./4.jpg";
import item5 from "./5.jpg";
import item6 from "./6.jpg";
import item7 from "./7.jpg";
import item8 from "./8.jpg";
import item9 from "./9.mp4";
import thumb9 from "./thumb9.webp";
import item10 from "./10.mp4";
import thumb10 from "./thumb10.webp";
import item11 from "./11.mp4";
import thumb11 from "./thumb11.webp";
import item12 from "./12.mp4";
import thumb12 from "./thumb12.webp";
import item13 from "./13.mp4";
import thumb13 from "./thumb13.webp";
import item14 from "./14.jpg";
import item15 from "./15.jpg";
import item16 from "./16.jpg";
import item17 from "./17.jpg";
import item22 from "./22.jpg";
import item23 from "./23.jpg";
import item24 from "./24.mp4";
import thumb24 from "./thumb24.webp";
import item25 from "./25.jpg";
import item26 from "./26.mp4";
import thumb26 from "./thumb26.webp";
import item27 from "./27.mp4";
import thumb27 from "./thumb27.webp";
import item28 from "./28.mp4";
import thumb28 from "./thumb28.webp";
import item29 from "./29.mp4";
import thumb29 from "./thumb29.webp";
import item30 from "./30.jpg";
import item31 from "./31.jpg";
import item32 from "./32.jpg";
import item33 from "./33.jpg";
import item34 from "./34.jpg";
import item35 from "./35.jpg";
import item36 from "./36.mp4";
import thumb36 from "./thumb36.webp";
import item37 from "./37.jpg";
import item38 from "./38.jpg";
import item39 from "./39.jpg";
import item40 from "./40.mp4";
import thumb40 from "./thumb40.webp";
import item41 from "./41.jpg";
import item42 from "./42.mp4";
import thumb42 from "./thumb42.webp";
import item43 from "./43.jpg";
import item44 from "./44.mp4";
import thumb44 from "./thumb44.webp";
import item45 from "./45.jpg";
import item46 from "./46.jpg";
import item47 from "./47.jpg";
import item48 from "./48.jpg";
import item49 from "./49.jpg";
import item50 from "./50.jpg";
import item51 from "./51.jpg";
import item52 from "./52.jpg";
import item53 from "./53.mp4";
import thumb53 from "./thumb53.webp";
import item54 from "./54.mp4";
import item55 from "./55.mp4";
import item56 from "./56.mp4";
import item57 from "./57.jpg";
import item58 from "./58.mp4";
import item59 from "./59.mp4";
import item60 from "./60.mp4";
import item61 from "./61.jpg";
import item62 from "./62.jpg";
import item63 from "./63.mp4";
import item64 from "./64.jpg";
import item65 from "./65.jpg";
import item66 from "./66.mp4";
import item67 from "./67.mp4";
import item68 from "./68.mp4";
import item69 from "./69.mp4";
import item70 from "./70.mp4";
import item71 from "./71.jpg";
import item72 from "./72.jpg";
import item73 from "./73.jpg";
import item74 from "./74.mp4";
import item75 from "./75.mp4";
import item76 from "./76.jpg";
import item77 from "./77.mp4";
import item78 from "./78.mp4";
import item79 from "./79.mp4";
import item80 from "./80.mp4";
import item81 from "./81.jpg";
import item82 from "./82.mp4";
import item83 from "./83.mp4";
import item84 from "./84.mp4";
import item85 from "./85.mp4";
import thumb54 from "./thumb54.webp";
import thumb55 from "./thumb55.webp";
import thumb56 from "./thumb56.webp";
import thumb58 from "./thumb58.webp";
import thumb59 from "./thumb59.webp";
import thumb60 from "./thumb60.webp";
import thumb63 from "./thumb63.webp";
import thumb66 from "./thumb66.webp";
import thumb67 from "./thumb67.webp";
import thumb68 from "./thumb68.webp";
import thumb69 from "./thumb69.webp";
import thumb70 from "./thumb70.webp";

import thumb74 from "./thumb74.webp";
import thumb75 from "./thumb75.webp";
import thumb77 from "./thumb77.webp";
import thumb78 from "./thumb78.webp";
import thumb79 from "./thumb79.webp";

import thumb80 from "./thumb80.webp";
import thumb82 from "./thumb82.webp";
import thumb83 from "./thumb83.webp";
import thumb84 from "./thumb84.webp";
import thumb85 from "./thumb85.webp";


const media = [
  { type: "image", src: item1 },
  { type: "video", src: item2, thumbnail: thumb2 },
  { type: "image", src: item3 },
  { type: "image", src: item4 },
  { type: "image", src: item5 },
  { type: "image", src: item6 },
  { type: "image", src: item7 },
  { type: "image", src: item8 },
  { type: "video", src: item9, thumbnail: thumb9 },
  { type: "video", src: item10, thumbnail: thumb10 },
  { type: "video", src: item11, thumbnail: thumb11 },
  { type: "video", src: item12, thumbnail: thumb12 },
  { type: "video", src: item13, thumbnail: thumb13 },
  { type: "image", src: item14 },
  { type: "image", src: item15 },
  { type: "image", src: item16 },
  { type: "image", src: item17 },
  { type: "image", src: item22 },
  { type: "image", src: item23 },
  { type: "video", src: item24, thumbnail: thumb24 },
  { type: "image", src: item25 },
  { type: "video", src: item26, thumbnail: thumb26 },
  { type: "video", src: item27, thumbnail: thumb27 },
  { type: "video", src: item28, thumbnail: thumb28 },
  { type: "video", src: item29, thumbnail: thumb29 },
  { type: "image", src: item30 },
  { type: "image", src: item31 },
  { type: "image", src: item32 },
  { type: "image", src: item33 },
  { type: "image", src: item34 },
  { type: "image", src: item35 },
  { type: "video", src: item36, thumbnail: thumb36 },
  { type: "image", src: item37 },
  { type: "image", src: item38 },
  { type: "image", src: item39 },
  { type: "video", src: item40, thumbnail: thumb40 },
  { type: "image", src: item41 },
  { type: "video", src: item42, thumbnail: thumb42 },
  { type: "image", src: item43 },
  { type: "video", src: item44, thumbnail: thumb44 },
  { type: "image", src: item45 },
  { type: "image", src: item46 },
  { type: "image", src: item47 },
  { type: "image", src: item48 },
  { type: "image", src: item49 },
  { type: "image", src: item50 },
  { type: "image", src: item51 },
  { type: "image", src: item52 },
  { type: "video", src: item53, thumbnail: thumb53 },
  { type: "video", src: item54, thumbnail: thumb54 },
  { type: "video", src: item55, thumbnail: thumb55 },
  { type: "video", src: item56, thumbnail: thumb56 },
  { type: "image", src: item57 },
  { type: "video", src: item58, thumbnail: thumb58 },
  { type: "video", src: item59, thumbnail: thumb59 },
  { type: "video", src: item60, thumbnail: thumb60 },
  { type: "image", src: item61 },
  { type: "image", src: item62 },
  { type: "video", src: item63, thumbnail: thumb63 },
  { type: "image", src: item64 },
  { type: "image", src: item65 },
  { type: "video", src: item66, thumbnail: thumb66 },
  { type: "video", src: item67, thumbnail: thumb67 },
  { type: "video", src: item68, thumbnail: thumb68 },
  { type: "video", src: item69, thumbnail: thumb69 },
  { type: "video", src: item70, thumbnail: thumb70 },
  { type: "image", src: item71 },
  { type: "image", src: item72 },
  { type: "image", src: item73 },
  { type: "video", src: item74, thumbnail: thumb74 },
  { type: "video", src: item75, thumbnail: thumb75 },
  { type: "image", src: item76 },
  { type: "video", src: item77, thumbnail: thumb77 },
  { type: "video", src: item78, thumbnail: thumb78 },
  { type: "video", src: item79, thumbnail: thumb79 },
  { type: "video", src: item80, thumbnail: thumb80 },
  { type: "image", src: item81 },
  { type: "video", src: item82, thumbnail: thumb82 },
  { type: "video", src: item83, thumbnail: thumb83 },
  { type: "video", src: item84, thumbnail: thumb84 },
  { type: "video", src: item85, thumbnail: thumb85 },
];

export default media;
