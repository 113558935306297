import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import enTranslation from './locales/en.json';
import sqTranslation from './locales/sq.json';
import mkTranslation from './locales/mk.json';
import trTranslation from './locales/tr.json';

// Check localStorage for the saved language or default to 'en'
const savedLanguage = localStorage.getItem('language') || 'en';

i18n.use(initReactI18next).init({
  resources: {
    en: {
      translation: enTranslation,
    },
    sq: {
      translation: sqTranslation,
    },
    mk: {
      translation: mkTranslation,
    },
    tr: {
      translation: trTranslation,
    },
  },
  lng: savedLanguage, // Set the initial language from localStorage
  fallbackLng: 'en', // Fallback to English if language is unavailable
  interpolation: {
    escapeValue: false, // react already safes from xss
  },
});

// Save the selected language in localStorage when it's changed
i18n.on('languageChanged', (lng) => {
  localStorage.setItem('language', lng);
});

export default i18n;
