import React, { useState, useEffect, useCallback, useRef } from "react";
import "./Navbar.css";
import { BurgerMenu, LangugaeIcon } from "../../assets/Icons/Icons";
import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet-async";

const Navbar = () => {
  const { t, i18n } = useTranslation();
  const [isVisible, setIsVisible] = useState(true);
  const [lastScrollY, setLastScrollY] = useState(0);
  const [openLanguage, setOpenLanguage] = useState(false);
  const [openBurgerMenu, setOpenBurgerMenu] = useState(false);
  const [closing, setClosing] = useState(false);
  const burgerMenuRef = useRef(null);

  const handleScroll = useCallback(() => {
    if (window.scrollY > lastScrollY && window.scrollY > 100) {
      setIsVisible(false);
    } else {
      setIsVisible(true);
    }
    setLastScrollY(window.scrollY);
  }, [lastScrollY]);

  const handleClickOutside = useCallback((event) => {
    if (
      burgerMenuRef.current &&
      !burgerMenuRef.current.contains(event.target)
    ) {
      setClosing(true);
      setTimeout(() => setOpenBurgerMenu(false), 300); // Close after animation
    }
  }, []);

  const toggleBurgerMenu = () => {
    if (openBurgerMenu) {
      setClosing(true);
      setTimeout(() => setOpenBurgerMenu(false), 300); // Close after animation
    } else {
      setOpenBurgerMenu(true);
      setClosing(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      window.removeEventListener("scroll", handleScroll);
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [handleScroll, handleClickOutside]);

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };

  return (
    <>
      <Helmet>
        <title>
          Befix – Professional Plastering, Insulation, and Renovation
          Services
        </title>
        <meta
          name="description"
          content="Befix is a leading provider of plastering, insulation, and renovation services in North Macedonia. Dedicated to quality and customer satisfaction in every project."
        />
        <meta
          name="keywords"
          content="Befix, befix, Be-Fix, BEFIX, be-fix, Plastering company North Macedonia, Professional plastering, Insulation services, Renovation experts, Building renovation, Interior design, Exterior design, Wall painting, Construction services, Reliable plastering, Residential and commercial plastering, Experienced renovators, Home renovation, North Macedonia plastering, Skopje, Tetovo, Bitola, Ohrid, Prilep, Kumanovo, Strumica, Veles, Gostivar, Štip, Quality plastering services, Befix values, Commitment to excellence, Customer satisfaction, Trusted plastering company, Suvatim, Dekorim, Renovim, Maqedoni e Veriut, Гипс услуги, Изолација, Реновирање услуги"
        />

        {/* Open Graph Meta Tags for Social Sharing */}
        <meta
          property="og:title"
          content="Befix – North Macedonia"
        />
        <meta
          property="og:description"
          content="Discover Befix's dedication to quality in plastering, insulation, and other services across North Macedonia. Our mission is customer satisfaction and excellence in every project."
        />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://befix.mk/about" />
        <meta
          property="og:image"
          content="https://befix.mk/images/og-image.png"
        />

        {/* Twitter Card Meta Tags */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta
          name="twitter:title"
          content="Befix – Plastering, Insulation, and Renovation Experts"
        />
        <meta
          name="twitter:description"
          content="Befix, a trusted provider of plastering, insulation, and other services in North Macedonia, committed to quality and customer satisfaction."
        />
        <meta
          name="twitter:image"
          content="https://befix.mk/images/twitter-image.png"
        />
      </Helmet>
      <nav
        className={`navbar-fixed navbar-transition ${
          isVisible ? "navbar-visible" : "navbar-hidden"
        }`}
      >
        <div className="navbar-container">
          <div className="navbar-links primaryRedBg">
            <a
              href="/"
              className="navbar-link"
              alt="This link redirect you to home page!"
            >
              {t("nav1")}
            </a>
            <a
              href="/about"
              className="navbar-link"
              alt="This link redirect you to about page!"
            >
              {t("nav2")}
            </a>
            <a
              href="/gallery"
              className="navbar-link"
              alt="This link redirect you to gallery page!"
            >
              {t("nav3")}
            </a>
            <a
              href="/services"
              className="navbar-link"
              alt="This link redirect you to services page!"
            >
              {t("nav4")}
            </a>
          </div>
          <button
            className="navbar-contact primaryRedText"
            onClick={() => setOpenLanguage(!openLanguage)}
          >
            {!openLanguage ? (
              <LangugaeIcon />
            ) : (
              <>
                <button
                  className="navbar-b0"
                  onClick={() => {
                    changeLanguage("en");
                    setOpenLanguage(false);
                  }}
                >
                  EN
                </button>
                <button
                  className="navbar-b0"
                  onClick={() => {
                    changeLanguage("sq");
                    setOpenLanguage(false);
                  }}
                >
                  SQ
                </button>
                <button
                  className="navbar-b0"
                  onClick={() => {
                    changeLanguage("mk");
                    setOpenLanguage(false);
                  }}
                >
                  MK
                </button>
                <button
                  className="navbar-b0"
                  onClick={() => {
                    changeLanguage("tr");
                    setOpenLanguage(false);
                  }}
                >
                  TR
                </button>
                <LangugaeIcon />
              </>
            )}
          </button>
        </div>

        <div className="navbar-icons-container">
          <button
            className="navbar-language-menu"
            onClick={() => setOpenLanguage(!openLanguage)}
          >
            {!openLanguage ? (
              <LangugaeIcon />
            ) : (
              <>
                <button
                  className="navbar-black"
                  onClick={() => {
                    changeLanguage("en");
                    setOpenLanguage(false);
                  }}
                >
                  EN
                </button>
                <button
                  className="navbar-black"
                  onClick={() => {
                    changeLanguage("sq");
                    setOpenLanguage(false);
                  }}
                >
                  SQ
                </button>
                <button
                  className="navbar-black"
                  onClick={() => {
                    changeLanguage("mk");
                    setOpenLanguage(false);
                  }}
                >
                  MK
                </button>
                <button
                  className="navbar-black"
                  onClick={() => {
                    changeLanguage("tr");
                    setOpenLanguage(false);
                  }}
                >
                  TR
                </button>
                <LangugaeIcon />
              </>
            )}
          </button>
          <div className="navbar-burger-menu" onClick={toggleBurgerMenu}>
            <BurgerMenu />
          </div>
        </div>

        {openBurgerMenu && (
          <div
            className={`navbar-burger-dropdown ${closing ? "closing" : ""}`}
            ref={burgerMenuRef}
          >
            <a
              href="/"
              className="navbar-burger-link"
              alt="This link redirect you to home page!"
            >
              {t("nav1")}
            </a>
            <hr />
            <a
              href="/about"
              className="navbar-burger-link"
              alt="This link redirect you to about page!"
            >
              {t("nav2")}
            </a>
            <hr />
            <a
              href="/gallery"
              className="navbar-burger-link"
              alt="This link redirect you to gallery page!"
            >
              {t("nav3")}
            </a>
            <hr />
            <a
              href="/services"
              className="navbar-burger-link"
              alt="This link redirect you to services page!"
            >
              {t("nav4")}
            </a>
          </div>
        )}
      </nav>
    </>
  );
};

export default Navbar;
