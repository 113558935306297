import React from "react";
import "./WhereWeOperateSection.css";
import Header from "../../Header/Header.jsx";
import { Colors } from "../../../assets/colors/colors.js";
import {
  FacebookIcon,
  InstagramIcon,
  MailIcon,
  PhoneIcon,
  WhatsappIcon,
} from "../../../assets/Icons/Icons.jsx";
import { useTranslation } from "react-i18next";

const WhereWeOperateSection = () => {
  const { t } = useTranslation();
  return (
    <section className="operate-container paddingV">
      <div className="operate-header">
        <Header
          top={t("OperatingSection.whereWe")}
          bottom={t("OperatingSection.operate")}
          arrowColor={Colors.primaryRed}
          bgColor={Colors.white}
          textColor1={Colors.white}
          textColor2={Colors.white}
        />
      </div>
      <div className="jeju-font paddingV operate-font">
        <div className="operate-text-container">
          <p>
            {t("OperatingSection.at")}{" "}
            <span className="jockey-font">Befix,</span>{" "}
            {t("OperatingSection.wePride")}{" "}
            <span className="jockey-font">{t("OperatingSection.nmk")}</span>{" "}
            {t("OperatingSection.noMatter")}
          </p>
          <p className="wwo-hidden">
            {t("OperatingSection.ifYou")}{" "}
            <span className="jockey-font">Befix</span>{" "}
            {t("OperatingSection.befixIsHere")}
          </p>
          <div className="gallery-links">
            <div className="operate-flex">
              <a
                className="operate-flex-center"
                href="mailto:befixag@gmail.com"
              >
                <MailIcon color1={Colors.white} color2={Colors.primaryRed} />
              </a>
              <a
                className="operate-flex-center"
                href="tel:+38971601608"
              >
                <PhoneIcon color1={Colors.white} color2={Colors.primaryRed} />
              </a>
              <a
                className="operate-flex-center"
                href="https://www.facebook.com/befixag"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FacebookIcon color1={Colors.white} />
              </a>
              <a
                className="operate-flex-center"
                href="https://wa.me/+38971601608"
                target="_blank"
                rel="noopener noreferrer"
              >
                <WhatsappIcon color1={Colors.white} />
              </a>
              <a
                className="operate-flex-center"
                href="https://www.instagram.com/befix.ag"
                target="_blank"
                rel="noopener noreferrer"
              >
                <InstagramIcon color1={Colors.white} />
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default WhereWeOperateSection;
